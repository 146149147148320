Space1.WebSocketConnection = class{
    
    constructor(options) {
        this.STATUS = { DISCONNECTED: 0, CONNECTED: 1 };
        this.RECONNECT_TIMEOUT = 5000;
        this._webSocket = null;
        this._url = null;
        this._accessToken = null;
        this._log = false;
        this._onConnectedCallback = null;
        this._onDisconnectedCallback = null;
        this._onCallRequestedCallback = null;
        this._onCallCancelledCallback = null;
        this._onCallResponseCallback = null;
        this._onErrorCallback = null;
        this._virtualCollaborationObject = {};
        this._status = 0;
        this._uuid = null;
        this._isAwaitingResponse = false;
        this._disablePersistance = false;

        if (options) {
            if (options.disablePersistance) {
                this._disablePersistance = options.disablePersistance;
            }
        }

        window.addEventListener('beforeunload', this.disconnect());
    }

    isConnected() {
        return this._status === this.STATUS.CONNECTED;
    }

    isDisconnected() {
        return this._status === this.STATUS.DISCONNECTED;
    }

    isAwaitingResponse() {
        return this._isAwaitingResponse;
    }

    init(url, logEnabled, token) {
        this._url = this._parseUrl(url);

        if (token) {
            this._accessToken = token;
        }

        if (logEnabled)
            this._log = true;

        if (!this._disablePersistance) {
            const rastatus = parseInt(window.sessionStorage.getItem("ra-status"));
            if (rastatus !== this.STATUS.DISCONNECTED) { // 0 = disconnected
                this.connect();
            }
        }
    }

    setAccessToken(token) {
        this._accessToken = token;
    };

    reconnect() {
        console.log("Connection lost. Trying to reconnect in " + (this.RECONNECT_TIMEOUT / 1000) +"... ");
        setTimeout( () =>{
            console.log("Reconnecting... ");
            this.connect();
        }, this.RECONNECT_TIMEOUT);
    }

    connect(persist) {
        this.disconnect();

        let self = this;

        if (!this._url || this._url == null || this._url.href === "") {
            if (this._log) {
                console.log("Not initialised.");
            }
            if (this._onErrorCallback) {
                this._onErrorCallback("Not initialized.");
            }
            return;
        }




        this._getWsEndpoint(
            (wsUrl)=> {
                this._webSocket = new WebSocket(wsUrl + "?type=web");
                this._webSocket.uuid = this._generateUUID();
                // this._webSocket.addEventListener("open", event => {
                //     console.log("LOG-websocket-EVENT: "+ event);
                // })

                this._webSocket.onopen = () => {
                    console.log("ON-Open");
                    this._uuid = this._webSocket.uuid;
                    if (this._log) {
                        console.log("Connected");
                    }
                    if (this._onConnectedCallback) {
                        this._onConnectedCallback();
                    }
                    if (this._accessToken && this._accessToken !== "") {
                        const authMessage = { action: "authenticate", token: this._accessToken };
                        this._webSocket.send(JSON.stringify(authMessage));
                    }
                    this._status = this.STATUS.CONNECTED;
                };

                this._webSocket.onmessage = (evt) => {

                    if (this._webSocket.uuid !== this._uuid) {
                        this._webSocket.close();
                        return;
                    }

                    const received = JSON.parse(evt.data);

                    if (received.action == "callrequest") {
                        if (this._onCallRequestedCallback) {
                            this._onCallRequestedCallback(received.callerUserId, received.callerDisplayName, received.roomName, received.sessionId, received.parameters);
                        }
                    }
                    if (received.action == "callcancelled") {
                        if (this._onCallCancelledCallback) {
                            this._onCallCancelledCallback(received.receivingUserId, received.callerUserId);
                        }
                    }
                    if (received.action == "callresponse") {
                        if (this.isAwaitingResponse() && this.isConnected()) {
                            this._isAwaitingResponse=false;
                            if (this._onCallResponseCallback) {
                                this._onCallResponseCallback(received.accepted);
                            }
                        }
                    }

                    if (this._log) {
                        console.log(evt.data);
                    }
                };

                this._webSocket.onerror = (evt) => {
                    if (this._uuid != null && this._webSocket.uuid !== this._uuid) {
                        return;
                    }
                    if (this._log) {
                        console.log("Socket error: " + evt.message);
                    }

                    if (this._status !== this.STATUS.DISCONNECTED) {
                        this.reconnect();
                        if (this._onErrorCallback) {
                            this._onErrorCallback("Socket error: " + evt.message);
                        }
                    }
                };

                this._webSocket.onclose = (e) => {
                    if (this._webSocket.uuid !== this._uuid) {
                        return;
                    }
                    if (this._log) {
                        console.log("Disconnected");
                    }

                    if (e.code === 1000 || e.code >= 4000) {
                        console.log("[WS]: Closed");
                    }
                    else if (this._status !== this.STATUS.DISCONNECTED) {
                        self.reconnect();
                    }

                    if (this._onDisconnectedCallback) {
                        this._onDisconnectedCallback();
                    }

                    this._uuid = null;
                };

                if (persist && window.sessionStorage) {
                    sessionStorage.setItem("ra-status", this.STATUS.CONNECTED);
                }
            }
        );
    };

    setOnConnected(callback) {
        this._onConnectedCallback = callback;
    };

    disconnect(persist) {
        if (this._webSocket) {
            if (this._webSocket.readyState === WebSocket.OPEN) {
                this._webSocket.close();
            }
        }
        if (!this._disablePersistance) {
            if (persist && window.sessionStorage) {
                this._status = this.STATUS.DISCONNECTED;
                sessionStorage.setItem("ra-status", this.STATUS.DISCONNECTED);
            }
        } else {
            this._status = this.STATUS.DISCONNECTED;
        }
    }

    disconnectAndDispose () {
        this._status = this.STATUS.DISCONNECTED;
        if (this._webSocket) {
            if (this._webSocket.readyState === WebSocket.OPEN) {
                this._webSocket.close();
            }
        }
    }

    setOnDisconnected(callback) {
        this._onDisconnectedCallback = callback;
    }

    setOnError(callback) {
        this._onErrorCallback = callback;
    }
    setOnCallRequested(callback) {
        this._onCallRequestedCallback = callback;
    }
    setOnCallCancelled(callback) {
        this._onCallCancelledCallback = callback;
    }
    setOnCallResponse(callback) {
        this._onCallResponseCallback = callback;
    }

    cancelCall(receivingUserId, callerUserId) {

        if (this.isAwaitingResponse()) {
            this._isAwaitingResponse = false;
            const cancelledMessage = {
                action: "callcancelled",
                receivingUserId: receivingUserId,
                callerUserId: callerUserId
            };
            this._webSocket.send(JSON.stringify(cancelledMessage));
        }

        if (!this._disablePersistance) {
            if (window.sessionStorage) {
                const rastatus = parseInt(window.sessionStorage.getItem("ra-status"));
                if (rastatus === this.STATUS.DISCONNECTED) { // 0 = disconnected
                    this.disconnect();
                }
            }
        }
    };

    sendCallResponse(callerUserId, response) {
        const responseMessage = {action: "callresponse", callerUserId: callerUserId};

        responseMessage.accepted = false;

        if (response == true) {
            responseMessage.accepted = true;
        }

        this._webSocket.send(JSON.stringify(responseMessage));
    }


    getAvailableUsers(onDone, onFail, filter) {

        let userfilter = "all";

        if (filter) {
            userfilter = filter;
        }

        const request = new XMLHttpRequest();
        request.open('GET', this._url.protocol + '//' + this._url.host + '/api/RemoteAssistance/users/' + userfilter + '?Api-Version=1.0&access_token=' + this._accessToken, true);
        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                const data = JSON.parse(request.responseText);
                if (onDone) {
                    onDone(data.remoteAssistanceUsers);
                }

            } else {
                // We reached our target server, but it returned an error
                if (this._log) {
                    console.log("Request Failed: " + request.status);
                }
                if (onFail)
                    onFail(request.status);
            }
        };

        request.onerror = (ev) => {
            // There was a connection error of some sort
            if (this._log) {
                console.log("Request Failed: " + ev.error);
            }
            if (onFail)
                onFail(ev.error);
        };

        request.send();

    }

    callUser(userid, onDone, onFail, parameters) {


        if (!this._webSocket || this._webSocket.readyState != WebSocket.OPEN) {
            this.connect();
        }

        const request = new XMLHttpRequest();
        request.open('POST', this._url.protocol + '//' + this._url.host + '/api/RemoteAssistance/CallUser/' + userid + '/false?Api-Version=1.0&access_token=' + this._accessToken, true);
        request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        request.onload =  () => {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                const data = JSON.parse(request.responseText);
                this._isAwaitingResponse = true;
                if (onDone) {
                    onDone(data);
                }
            } else {
                // We reached our target server, but it returned an error
                if (request.status == 402) {
                    Space1.UI.showAlert("Error", JSON.parse(request.responseText));
                }

                if (this._log) {
                    console.log("Request Failed: " + request.status);
                }
                if (onFail)
                    onFail(request.status);
            }
        };

        request.onerror = (ev) => {
            if (this._log) {
                console.log("Request Failed: " + ev.error);
            }
            if (onFail)
                onFail(ev.error);
        };

        if (parameters) {
            request.send(JSON.stringify(parameters));
        } else {
            request.send();
        }
    };

    callIpCamera(cameraId, onDone, onFail, parameters) {

        if (!this._webSocket || this._webSocket.readyState != WebSocket.OPEN) {
            this.connect();
        }

        const request = new XMLHttpRequest();
        request.open('POST', this._url.protocol + '//' + this._url.host + '/api/RemoteAssistance/CallIpCamera/' + cameraId + '?Api-Version=1.0&access_token=' + this._accessToken, true);
        request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                const data = JSON.parse(request.responseText);
                if (onDone) {
                    onDone(data);
                }
            } else {
                // We reached our target server, but it returned an error
                if (this._log) {
                    console.log("Request Failed: " + request.status);
                }
                if (onFail)
                    onFail(request.status);
            }
        };

        request.onerror =  (ev) =>{
            if (this._log) {
                console.log("Request Failed: " + ev.error);
            }
            if (onFail)
                onFail(ev.error);
        };

        if (parameters) {
            request.send(JSON.stringify(parameters));
        } else {
            request.send();
        }
    }

    inviteUser (userid, roomname, sessionId, onDone, onFail, parameters) {


        if (!this._webSocket || this._webSocket.readyState != WebSocket.OPEN) {
            this.connect();
        }

        const request = new XMLHttpRequest();
        request.open('POST', this._url.protocol + '//' + this._url.host + '/api/RemoteAssistance/inviteUser/' + userid + '/' + roomname + '/' + sessionId+'?Api-Version=1.0&access_token=' + this._accessToken, true);
        request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                const data = JSON.parse(request.responseText);
                this._isAwaitingResponse = true;
                if (onDone) {
                    onDone(data);
                }
            } else {
                // We reached our target server, but it returned an error
                if (this._log) {
                    console.log("Request Failed: " + request.status);
                }
                if (onFail)
                    onFail(request.status);
            }
        };

        request.onerror = (ev) => {
            if (this._log) {
                console.log("Request Failed: " + ev.error);
            }
            if (onFail)
                onFail(ev.error);
        };

        if (parameters) {
            request.send(JSON.stringify(parameters));
        } else {
            request.send();
        }
    }

    _getWsEndpoint(onCompleted) {

        let wsUrl = ((this._url.protocol === "https:") ? "wss://" : "ws://") + this._url.host + "/ws";
        const request = new XMLHttpRequest();
        request.open('GET', this._url.protocol + '//' + this._url.host + '/api/RemoteAssistance/GetWebSocketEndpoint/?Api-Version=1.0&access_token=' + this._accessToken, true);

        request.onload = () =>{
            if (request.status >= 200 && request.status < 400) {
                // Success!
                const data = JSON.parse(request.responseText);
                wsUrl = data.url;

            } else {
                // We reached our target server, but it returned an error
                if (this._log) {
                    console.log("Request Failed: " + request.status);
                }
            }
            if (onCompleted)
                onCompleted(wsUrl);
        };

        request.onerror = (ev) =>{
            // There was a connection error of some sort
            if (this._log) {
                console.log("Request Failed: " + ev.error);
            }
            if (onCompleted)
                onCompleted(wsUrl);
        };

        request.send();

    }

    _parseUrl(url) {

        const urlObject = {};
        urlObject.href = url;

        const hashDivision = url.split('#');
        const withoutHash = hashDivision[0];

        // If fragment identifier (#) exists, add it to object
        if (hashDivision.length > 1) {
            urlObject.hash = '#' + hashDivision[1];
        } else {
            urlObject.hash = '';
        }

        // Divide into base url/path and query string
        const hostDivision = withoutHash.split('?');
        const withoutQuery = hostDivision[0];

        // If query string exists, add the parameters to searchParams object
        let searchParams = '';
        urlObject.search = '';

        if (hostDivision.length > 1) {
            searchParams = {};

            // Add full query string to object
            urlObject.search = '?' + hostDivision[1];

            // Divide into parameters array
            const paramDivision = hostDivision[1].split('&');

            // Iterate over parameters
            for (let i = 0; i < paramDivision.length; i++) {
                const equalDivision = paramDivision[i].split('=');
                const key = equalDivision[0];
                const value = equalDivision[1];

                searchParams[key] = value;
            }
        }

        urlObject.searchParams = searchParams;

        // Divide into host and base url/path sections
        const protocolDivision = withoutQuery.split('//');

        // If no protocol was in url, default to http:
        if (protocolDivision.length == 1) {
            protocolDivision[1] = protocolDivision[0];
            protocolDivision[0] = 'http:';
        }

        const protocol = protocolDivision[0];
        const withoutProtocol = protocolDivision[1];

        // Add protocol to object
        urlObject.protocol = protocol;

        // Divide into base url and path sections
        let pathDivision = withoutProtocol.split('/');

        // Edge case for '/' ending causing empty item
        if (pathDivision[pathDivision.length - 1] === '') {
            pathDivision = pathDivision.slice(0, pathDivision.length - 1);
        }

        // Set host
        const host = pathDivision[0];
        urlObject.host = host;

        // Divide into hostname and port sections
        const portDivision = host.split(':');

        // Set default port (empty means 80)
        let port = '';

        if (portDivision.length > 1) {
            port = portDivision[1];
        }

        urlObject.port = port;

        // Save hostname to object
        urlObject.hostname = portDivision[0];

        // Reconstruct path
        if (pathDivision.length > 1) {
            let path = '';
            for (let i = 1; i < pathDivision.length; i++) {
                path += '/' + pathDivision[i];
            }

            // Add path to object
            urlObject.path = path;
        } else {
            urlObject.path = '';
        }

        return urlObject;
    }

    _generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

};